import {
  CustomTheme,
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import {
  all,
  allPass,
  complement,
  has,
  isEmpty,
  lte,
  path,
  pathEq,
  pathOr,
  pipe,
  propEq,
  propOr,
  propSatisfies,
  startsWith,
} from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { isPageWithId } from 'skoda-xperience-model-management';
import { onNextClick, onPreviousClick } from '../surveyCollector/screenStreams';
import {
  BrandsEnum,
  SurveyMetadata,
  SurveyProgressData,
} from '../surveyCollector/SurveyCollector';
import LanguageSelect from './components/LanguageSelect';
import SurveyNavigation from './components/SurveyNavigation';
import SurveyProgress from './components/SurveyProgress';
import { reloadWithLanguage } from './reloadWithLanguage';
import { State, StateType } from './states';
import { UIState } from './UIState';
import { useWindowSize } from './utils/screen';
import { autoNextEnabledForSurvey } from 'src/surveyModel/flags';

export const useZoomLevel = (brand, isIntro?): boolean => {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const minScreenHeightLow150 = 500;
  const minScreenHeightHigh150 = 620;
  const minScreenWidthLow150 = 1279;
  const minScreenWidthHigh150 = 1300;
  const minScreenHeightLow125 = 700;
  const minScreenHeightHigh125 = 750;
  const minScreenWidthLow125 = 1500;
  const minScreenWidthHigh125 = 1550;

  let BrowserType: { [key: string]: string } | undefined;
  (function (BrowserType) {
    BrowserType['OPERA'] = 'Opera';
    BrowserType['OPERA2'] = 'OPR';
    BrowserType['EDGE'] = 'Edg';
    BrowserType['CHROME'] = 'Chrome';
    BrowserType['SAFARI'] = 'Safari';
    BrowserType['FIREFOX'] = 'Firefox';
    BrowserType['UNKNOWN'] = 'unknown';
  })(BrowserType || (BrowserType = {}));
  const detectBrowser = () => {
    if (BrowserType === undefined) {
      return 'unknown';
    }

    return Object.values(BrowserType).find(
      (browser) => navigator.userAgent.indexOf(browser) !== -1
    );
  };

  const isFF = detectBrowser() === 'Firefox';

  /**
   * * Turn off scaling for questionaire for chrome and firefox as it was requested
   * if (brand === BrandsEnum.SKODA) {
   *  if (
   *    screenHeight > minScreenHeightLow125 &&
   *    screenHeight < minScreenHeightHigh125 &&
   *    screenWidth > minScreenWidthLow125 &&
   *    screenWidth < minScreenWidthHigh125
   *  ) {
   *    if (isFF) {
   *      document.body.style.transform =
   *        'scale(0.8) translate(-12.47%, -12.47%)';
   *      document.body.style.width = 'calc(100vw * 1.25)';
   *      document.body.style.height = 'calc(100vh * 1.25)';
   *    } else {
   *      // tslint:disable-next-line
   *      document.body.style.zoom = '0.8';
   *    }
   *    return true;
   *  } else if (
   *    screenHeight > minScreenHeightLow150 &&
   *    screenHeight < minScreenHeightHigh150 &&
   *    screenWidth > minScreenWidthLow150 &&
   *    screenWidth < minScreenWidthHigh150
   *  ) {
   *    if (isFF) {
   *      document.body.style.transform = 'scale(0.6) translate(-33.3%, -33.3%)';
   *      document.body.style.width = 'calc(100vw * 1.666)';
   *      document.body.style.height = 'calc(100vh * 1.666)';
   *    } else {
   *      // tslint:disable-next-line
   *      document.body.style.zoom = detectBrowser() === 'Edg' ? '1' : '0.6';
   *    }
   *    return true;
   *  }
   * }
   */
  return false;
};

const getContentFlex = (brand: BrandsEnum) => {
  switch (brand) {
    case BrandsEnum.SKODA: {
      return '1';
    }
    default: {
      return 'initial';
    }
  }
};

const getLogoStyle = (brand: BrandsEnum, theme: CustomTheme) => {
  switch (brand) {
    case BrandsEnum.SKODA: {
      return {
        margin: 'unset',
        marginTop: '11px',
        marginLeft: '16px',
        marginBottom: '15px',
      };
    }
    default: {
      return {
        margin: theme.spacing(),
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 0,
      };
    }
  }
};

const getContentTopMargin = (brand: BrandsEnum) => {
  switch (brand) {
    case BrandsEnum.SKODA: {
      return '-51px';
    }
    default: {
      return '0';
    }
  }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: '100%',
    overflow: theme.brandCode === BrandsEnum.SKODA ? 'auto' : 'unset',
    // transform: useZoomLevel(theme.brandCode, true) ? `scale(0.95)` : 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: '100%',
    },
    marginTop: getContentTopMargin(theme.brandCode),
    flex: getContentFlex(theme.brandCode),

    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing() / 2}px`,
      height: '100%',
    },
  },
  gridLogo: getLogoStyle(theme.brandCode, theme),
  logo: {
    float: 'right',
    maxHeight: '65px',
  },
  progress: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.between('lg', 'xl')]: {
      marginTop: theme.brandCode === BrandsEnum.SKODA ? '55px' : 'auto',
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      marginLeft: `24px`,
      marginRight: `24px`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 4px',
    },
  },
  screen: {
    minHeight: 300,
    overflowY: 'auto',
    overflowX: theme.brandCode === BrandsEnum.SKODA ? 'auto' : 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      minHeight: 'fit-content',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    flexWrap: 'nowrap',
  },
  divider: {
    color: '#f5f5f5',
    height: theme.brandCode === BrandsEnum.SKODA ? '1px' : 0,
  },
  pageTitle: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 960,
    width: '100%',
    borderWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: `${theme.spacing() / 2}px solid ${
      theme.palette.primary.main
    }`,
    paddingBottom: theme.spacing(),
    marginBottom: theme.spacing(),
    fontSize: theme.brandCode === BrandsEnum.SKODA ? '1.7rem' : '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },

  pageBackground: {
    [theme.breakpoints.between('lg', 'xl')]: {
      backgroundColor: '#f1f1f1',
      height: 'calc(100% - 66px)',
      width: '100%',
      overflow: 'auto',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '32px',
    },
  },
  pageFullBackground: {
    [theme.breakpoints.between('lg', 'xl')]: {
      backgroundColor: '#f1f1f1',
      height: 'calc(100% - 66px)',
      width: '100%',
      position: 'fixed',
      overflow: 'auto',
      top: '65px',
      left: '0px',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  pageForeground: {
    [theme.breakpoints.between('lg', 'xl')]: {
      backgroundColor: '#ffffff',
      padding: '60px',
      width: '824px',
      margin: '1.5rem auto',
    },
  },
  pageForegroundNarrow: {
    backgroundColor: '#ffffff',
    padding: '40px',
    [theme.breakpoints.between('lg', 'xl')]: {
      width: 'min-content',
    },
    [theme.breakpoints.only('md')]: {
      width: '608px',
      margin: '0 auto',
      padding: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '2rem 2rem',
      maxWidth: '608px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  pageForegroundThick: {
    [theme.breakpoints.only('xl')]: {
      backgroundColor: '#ffffff',
      margin: '1.5rem auto',
      padding: '60px',
      width: '900px',
    },
    [theme.breakpoints.only('lg')]: {
      backgroundColor: '#ffffff',
      margin: '1.5rem auto',
      padding: '60px',
      width: '900px',
    },
    [theme.breakpoints.only('md')]: {
      width: '900px',
      margin: '0 auto',
      padding: '2rem 2rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '2rem 2rem',
      maxWidth: '900px',
    },
  },
}));

type LayoutProps = {
  state: State;
};

const testGoogleReview = pathEq(
  ['screen', 'elements', '0', 'type'],
  'googleReview'
);
const isQuestion = propSatisfies(startsWith('question'), 'type');
const isNotQuestion = complement(isQuestion);
const isStandaloneScreen: (state: State) => Boolean = pipe(
  pathOr([], ['screen', 'elements']),
  allPass([isNotNilOrEmpty, all(isNotQuestion)])
);
const isIntro = pathEq(['screen', 'elements', 0, 'type'], 'intro');
const isOutro = pathEq(['screen', 'elements', 0, 'type'], 'outro');
const isQE = pathEq(['screen', 'elements', 0, 'type'], 'escape');
const isQP = pathEq(['screen', 'elements', 0, 'type'], 'personalAgreement');
const isSquareRate = pathEq(
  ['screen', 'elements', 0, 'type'],
  'questionSquareRating'
);
const isFillInBlocked = propEq('type', StateType.FillInBlocked);
const isConnectionError = propEq('type', StateType.ConnectionErrorDisplayed);
const isLoading = propEq('type', StateType.Loading);

const getGoBackDisabled = pathOr(false, ['screen', 'status', 'disableGoBack']);
const getProgress = pathOr<SurveyProgressData>({ total: 1, current: 0 }, [
  'screen',
  'progress',
]);

const getSurveyMetadata: (obj) => SurveyMetadata = propOr(
  {
    availableLanguages: [],
    surveyLanguage: 'en_US',
    directionality: 'ltr',
  },
  'surveyMetadata'
);
const hasOtherLanguages = pipe(
  pathOr(0, ['surveyMetadata', 'availableLanguages', 'length']),
  lte(2)
);

const changeFavicon = (href: string) => {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  favicon.href = href;
};
const changeTitle = (titleText: string) => {
  const title = document.getElementById('title');
  if (title) {
    title.textContent = titleText;
  }
};

const getPageTitle = (state: State): string | boolean => {
  const rawScreenElement = path(['screen', 'rawScreenElement'], state);
  if (
    rawScreenElement &&
    isPageWithId(rawScreenElement) &&
    has('title', rawScreenElement)
  ) {
    return pathOr(false, ['title'], rawScreenElement);
  }

  return false;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ state }) => {
  const theme = useTheme();
  const classes = useStyles();
  const windowSize = useWindowSize();
  const [
    localNextButtonOverride,
    setLocalNextButtonOverride,
  ] = React.useState(() => () => undefined);

  const btnOverride = (passedFunc: any) => {
    setLocalNextButtonOverride(passedFunc);
  };

  const isGoogleReview: boolean = testGoogleReview(state);
  if (isGoogleReview) {
    // @ts-ignore
    // lmao
    if (state.type == StateType.ScreenDisplayed) {
      // @ts-ignore
      state.screen.elements[0].componentProps.autoNextEnabled = autoNextEnabledForSurvey(state.surveyMetadata);
      // @ts-ignore
      state.screen.elements[0].componentProps.nextButtonOverride = btnOverride;
    }
  }

  const hideProcessElements =
    isStandaloneScreen(state) ||
    isFillInBlocked(state) ||
    isConnectionError(state);
  const showLanguageSwitch = path(
    ['surveyMetadata', 'surveySettings', 'showLanguageSwitch'],
    state
  );
  const showLanguageSelect =
    (showLanguageSwitch === 'allItems' || isIntro(state)) &&
    hasOtherLanguages(state);

  const goBackDisabled = getGoBackDisabled(state);
  const progress = getProgress(state);
  const metadata = getSurveyMetadata(state);
  // @ts-ignore
  const faviconPath = theme.images.favicon;
  // @ts-ignore
  const titleText = theme.title;

  const pageTitle = getPageTitle(state);

  React.useEffect(() => {
    changeFavicon(faviconPath);
    changeTitle(titleText);
    useZoomLevel(theme.brandCode, isIntro(state));
  }, [faviconPath, titleText, theme.brandCode, state]);

  const styleOverride = React.useMemo(
    () =>
      state.type === StateType.ScreenDisplayed
        ? {
            pageBackground: {
              alignItems:
                state.screen.elements.length > 3 ? 'flex-start' : undefined,
            },
          }
        : {},
    [state]
  );

  const foregroundPadding = React.useMemo(() => {
    if (state.type === StateType.ScreenDisplayed) {
      let paddingValue =
        state.screen.rawScreenElement?.type === 'personalAgreement' &&
        windowSize.width <= 1536 &&
        windowSize.height < 800
          ? '0 0.75rem'
          : undefined;

      if (
        state.screen.rawScreenElement?.type === 'questionSquareRating' &&
        windowSize.width < 1280
      ) {
        paddingValue = '0';
      }

      return paddingValue;
    }
    return undefined;
  }, [state, windowSize]);

  return (
    <MuiThemeProvider
      theme={{
        ...theme,
        direction: metadata.directionality,
      }}>
      {!isLoading(state) && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.root}
          style={{
            overflow:
              state.type === StateType.FinalScreenDisplayed &&
              state.screen.rawScreenElement?.type === 'outro'
                ? 'hidden'
                : 'auto',
          }}>
          <Grid item>
            <Grid container className={classes.header}>
              <Grid item className={classes.gridLogo}>
                <img
                  // @ts-ignore
                  src={theme.images.logo}
                  className={classes.logo}
                />
              </Grid>
              {showLanguageSelect && (
                <Grid item>
                  <LanguageSelect
                    currentLanguage={metadata.surveyLanguage}
                    languages={metadata.availableLanguages}
                    onChange={reloadWithLanguage}
                  />
                </Grid>
              )}
            </Grid>
            {theme.brandCode === BrandsEnum.SKODA && (
              <div
                style={{
                  display:
                    state.type === StateType.ScreenDisplayed &&
                    state.screen.rawScreenElement?.type === 'personalAgreement'
                      ? 'none'
                      : 'inherit',
                }}>
                <hr className={classes.divider} />
              </div>
            )}
            {!hideProcessElements && (
              <Grid item className={classes.progress}>
                <SurveyProgress
                  totalCount={progress.total}
                  completed={progress.current}
                  // @ts-ignore
                  image={theme.images.progressImagePath}
                  showValue
                />
              </Grid>
            )}
          </Grid>
          <div
            className={classes.content}
            style={
              hideProcessElements
                ? {
                    marginTop: 0,
                    justifyContent:
                      state.type === StateType.ScreenDisplayed &&
                      state.screen.rawScreenElement?.id === 'QP'
                        ? 'center'
                        : undefined,
                  }
                : undefined
            }>
            {theme.brandCode === BrandsEnum.SKODA &&
            !isIntro(state) &&
            !isOutro(state) ? (
              <div
                className={
                  !hideProcessElements
                    ? classes.pageBackground
                    : classes.pageFullBackground
                }
                style={styleOverride.pageBackground}>
                <div
                  className={
                    !isQE(state) && !isQP(state)
                      ? !isSquareRate(state)
                        ? classes.pageForeground
                        : classes.pageForegroundThick
                      : classes.pageForegroundNarrow
                  }
                  style={{
                    padding: foregroundPadding,
                    paddingBottom:
                      state.type === StateType.ScreenDisplayed &&
                      state.screen.rawScreenElement?.type ===
                        'personalAgreement' &&
                      windowSize.width < 960
                        ? '24px'
                        : undefined,
                  }}>
                  {pageTitle && (
                    <Grid item className={classes.pageTitle}>
                      {pageTitle}
                    </Grid>
                  )}

                  <Grid item className={classes.screen} id="screenQuestion">
                    <UIState />
                  </Grid>

                  {!hideProcessElements && (
                    <Grid item>
                      <SurveyNavigation
                        goBackDisabled={goBackDisabled}
                        onNextClick={onNextClick}
                        onPreviousClick={onPreviousClick}
                      />
                    </Grid>
                  )}

                  {isGoogleReview && (
                    <Grid item>
                      <SurveyNavigation
                      goBackDisabled={goBackDisabled}
                      onNextClick={localNextButtonOverride ?? onNextClick}
                      onPreviousClick={onPreviousClick}
                      />
                    </Grid>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {pageTitle && (
                  <Grid item className={classes.pageTitle}>
                    {pageTitle}
                  </Grid>
                )}

                <Grid item className={classes.screen} id="screenQuestion">
                  <UIState />
                </Grid>

                {!hideProcessElements && (
                  <Grid item>
                    <SurveyNavigation
                      goBackDisabled={goBackDisabled}
                      onNextClick={onNextClick}
                      onPreviousClick={onPreviousClick}
                    />
                  </Grid>
                )}
              </div>
            )}

          </div>
        </Grid>
      )}
    </MuiThemeProvider>
  );
};

export default Layout;
