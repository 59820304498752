import { ifElse, isEmpty, pipe, prop, when, anyPass } from 'ramda';
import { canGoNext, collectedDataProcessing } from './screenProcessing';
import { ScreenElementInstance, SurveyScreen } from './SurveyCollector';
import { isEscape, isPersonalAgreement } from 'skoda-xperience-model-management';

const controlInitialCollectedDataProcessing = (
  onNext,
  stopCollectedDataProcessing
) =>
  when(
    collectedDataProcessing,
    ifElse(
      canGoNext,
      () => onNext('collectedDataProcessing'),
      stopCollectedDataProcessing
    )
  );

const isScreenEmpty = pipe<SurveyScreen, ScreenElementInstance[], boolean>(
  prop('elements'),
  isEmpty
);

const continueWhenScreenIsEmpty = moveFunction =>
  when(isScreenEmpty, () => moveFunction('skipEmptyScreen'));

export const screenActionsFactory = (
  onNext,
  onPrevious,
  onAutoNext,
  stopCollectedDataProcessing
) => ([screen, move]) => {
  return pipe(
    controlInitialCollectedDataProcessing(onNext, stopCollectedDataProcessing),
    continueWhenScreenIsEmpty(
      move.moveDirection === 'next' ||
        anyPass([isEscape, isPersonalAgreement])(screen.rawElements[0])
        ? onNext
        : onPrevious
    )
  )(screen);
};
