import { over, pipe, when } from 'ramda';
import { versionLens } from '../getters';
import { processVersionOne } from './versionOne';
import { Survey } from '../../surveyCollector/SurveyCollector';
import {
  isVersion,
  replaceSecondaryToVisualType,
} from 'skoda-xperience-model-management';
import { processVersionTwo } from './versionTwo';
import { removeHiddenChoices } from './removeHiddenChoices';
import { processBeforeExitQuestion } from './processBeforeExitQuestion';
import { removeHiddenIntro } from './removeHiddenIntro';

const checkOrSetVersion = over(versionLens, (version = 1) => version) as (
  s: Survey
) => Survey;

export const surveyPreProcessing = pipe<
  Survey,
  Survey,
  Survey,
  Survey,
  Survey,
  Survey,
  Survey,
  Survey
>(
  checkOrSetVersion,
  removeHiddenChoices,
  removeHiddenIntro,
  replaceSecondaryToVisualType,
  // @ts-ignore
  when(isVersion(1), processVersionOne),
  when(isVersion(2), processVersionTwo),
  processBeforeExitQuestion
) as (s: Survey) => Survey;
