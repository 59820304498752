import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { localizationList } from 'skoda-xperience-model-management';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

interface LanguageSelectInnerProps {
  languages: string[];
  currentLanguage: string;
  onChange: (event) => void;
}

const LanguageSelect: React.FunctionComponent<LanguageSelectInnerProps> = ({
  languages,
  currentLanguage,
  onChange,
}) => {
  const classes = useStyles();

  const onChangeHandler = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="survey-language">
        <Trans i18nKey="ui.languageSelect.language">Language</Trans>
      </InputLabel>
      <Select
        data-test-id="surveyLanguage"
        value={currentLanguage}
        onChange={onChangeHandler}
        className={classes.selectEmpty}
        inputProps={{
          name: 'surveyLanguage',
          id: 'survey-language',
        }}>
        {languages.map((code: string) => (
          <MenuItem key={code} value={code}>
            {localizationList[code].localName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
