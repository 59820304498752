import { flatQuestionTree } from 'skoda-xperience-model-management';
import { reduce, map, mergeRight, assocPath, pipe, prop } from 'ramda';
import {
  Survey,
  Question,
  Choices,
} from '../../surveyCollector/SurveyCollector';

export const mergeChoices = (survey: Survey) => {
  const flattenedQuestionsTree = flatQuestionTree(survey.definition);
  const getChoicesFromTranslation: (id: string) => any = pipe<
    string,
    Question,
    any
  >(
    (id) => survey.translations[id] as Question,
    // @ts-ignore
    prop('choices')
  );

  return reduce(
    (acc, question: Question) => {
      if (question.choices) {
        const choicesFromTranslation = getChoicesFromTranslation(question.id);
        const mergeChoices = (choice: Choices): Choices => {
          return mergeRight<Choices, Choices>(
            choice,
            choicesFromTranslation[choice.value]
          );
        };
        const mergedChoices = map(mergeChoices, question.choices);
        const pathToChoices = ['translations', question.id, 'choices'];
        return assocPath(pathToChoices, mergedChoices, acc);
      }
      return acc;
    },
    survey,
    flattenedQuestionsTree
  );
};

export const processVersionTwo = pipe(mergeChoices);
