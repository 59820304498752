import { lensPath, path, pathOr } from 'ramda';
import {
  LoadingStandardResponse,
  Survey,
  SurveyMetadata,
} from '../surveyCollector/SurveyCollector';
import { localizationList } from 'skoda-xperience-model-management';
import { Direction } from '@material-ui/core/styles';

export const metadataLens = lensPath(['metadata']);
export const versionLens = lensPath(['metadata', 'version']);

export const getCommonTranslations = path<object>(['translations', 'common']);

const getDirectionality = (locale) =>
  pathOr('ltr', [locale, 'directionality'], localizationList) as Direction;

export const createSurveyMetadata = (
  response: LoadingStandardResponse,
  survey: Survey
) => {
  const definedMetadata = survey.metadata || {};
  return {
    ...definedMetadata,
    surveyLanguage: response.surveyLanguage || 'en_US',
    availableLanguages: response.availableLanguages || [],
    directionality: getDirectionality(response.surveyLanguage || 'en_US'),
  } as SurveyMetadata;
};
