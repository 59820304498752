import { assoc, over, pipe, when } from 'ramda';
import { genderImageMapping } from '../../elementTypes/Gender';
import { metadataLens } from '../getters';
import {
  definitionLens,
  hasTempMetadataFallback,
  moveTempMetadata,
  transformBeforeEscape,
} from 'skoda-xperience-model-management';
import { Survey } from '../../surveyCollector/SurveyCollector';

const setVersion = assoc('version', 1);
const setVariant = assoc('variant', 'SEPARATE_TRANSLATIONS');
const setGenderImageMapping = assoc(
  'genderImageMapping',
  genderImageMapping.ORDER
);

export const processVersionOne = pipe<Survey, Survey, Survey, Survey>(
  over(
    metadataLens,
    pipe(
      setVersion,
      setVariant,
      setGenderImageMapping
    )
  ),
  when(hasTempMetadataFallback, moveTempMetadata),
  over(definitionLens, transformBeforeEscape)
);
