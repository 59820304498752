import {
  makeStyles,
  Typography,
  MuiThemeProvider,
  useTheme,
  CustomTheme,
} from '@material-ui/core';
import useInterval from '@use-it/interval';
import nl2br from 'nl2br';
import * as React from 'react';
import { OutroProps } from './ElementTypeInterfaces';
import thanksBg from '../static/images/skoda2.png';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};
setTimeout(function () {
  preloadImage(thanksBg);
}, 500);

const useStyles = makeStyles((theme: CustomTheme) => ({
  textBorder: {
    borderColor: theme.palette.primary.main,
    border: '3px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
    },
  },
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    zIndex: 1,
    left: 0,
    top: '65px',
    [theme.breakpoints.down('lg')]: {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA ? '#0e3a2f' : 'none',
      backgroundImage: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage:
        theme.brandCode === BrandsEnum.SKODA && theme && theme.thanks
          ? `url(${theme.thanks.backgroundImagePath})`
          : 'none',
    },
  },
  text: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  redirectText: {
    color: theme.brandCode === BrandsEnum.SKODA ? 'white' : undefined,
  },
  redirectLink: {
    color:
      theme.brandCode === BrandsEnum.SKODA
        ? theme.palette.primary.main
        : undefined,
  },
}));

const Outro: React.FunctionComponent<OutroProps> = ({
  text,
  redirect,
  redirectLink = '',
  redirectSeconds,
  isRedirect = false,
}) => {
  const [seconds, setSeconds] = React.useState(redirectSeconds as number);
  const showRedirectBlock = isRedirect;
  const classes = useStyles();
  const theme = useTheme();

  useInterval(
    () => {
      setSeconds((currentCount) => currentCount - 1);
    },
    showRedirectBlock ? 1000 : null
  );

  if (showRedirectBlock && seconds < 1) {
    window.location.href = redirectLink;
    return null;
  }

  let outroTextParts: React.ReactNode[] = [];
  if (showRedirectBlock) {
    const EXPRESSION_LINK = /(\$\{\ *linkText\ *\})/;
    const EXPRESSION_SECONDS = /(\$\{\ *seconds\ *\})/;
    const Link = (
      <a
        className={classes.redirectLink}
        style={{ textDecoration: 'none', zIndex: 1000 }}
        href={redirectLink}
        key="link">
        {redirect!.linkText}
      </a>
    );

    const outroTextSeconds = redirect!.text.replace(
      EXPRESSION_SECONDS,
      `${seconds}`
    );
    outroTextParts = outroTextSeconds.split(EXPRESSION_LINK);
    outroTextParts[1] = Link;
  }

  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      {theme.brandCode === BrandsEnum.SKODA ? (
        <div className={classes.background}>
          <div className={classes.text}>
            <div style={{ maxWidth: '50%', maxHeight: '50%' }}>
              <Typography
                color="primary"
                variant="h4"
                dangerouslySetInnerHTML={{ __html: nl2br(text) }}
              />
            </div>
            {showRedirectBlock && (
              <>
                <div
                  className={classes.redirectText}
                  style={{ marginTop: '20px' }}>
                  {outroTextParts}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Typography
            color="primary"
            className={classes.textBorder}
            variant="h3"
            dangerouslySetInnerHTML={{ __html: nl2br(text) }}
          />
          {showRedirectBlock && (
            <>
              <div
                className={classes.redirectText}
                style={{ marginTop: '20px' }}>
                {outroTextParts}
              </div>
            </>
          )}
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default Outro;
